<template>
  <div class="exercise_congratulations" v-if="'new' != profile.status">
    <div class="congratulations" style="display: block">
      <div class="congratulations_icon">
        <i class="atuicons atuicons-subsription"></i>
      </div>
      <div class="congratulations_t">No Active Membership!<br /></div>
    </div>
    <br />
    <button @click="onClick" class="button button_red">Buy Subscription</button>
    <br />
    <br />
  </div>
</template>
<script>
export default {
  name: "BuyAlert",
  methods: {
    redirect() {
      this.$router
        .push({ name: "Account", query: { buy: "true" } })
        .catch((error) => {
          console.log("router replace error onClick in BuyAlert", error);
        });
    },
    onClick() {
      this.redirect();
    },
  },
  created() {
    if ("new" == this.profile.status) {
      this.redirect();
    }
  },
  computed: {
    profile() {
      return this.$store.getters.profile;
    },
  },
};
</script>
<style scoped>
@import "~@/assets/css/workouts.css";
</style>
